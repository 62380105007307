import { useContext, useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";
import { useMutation } from "react-query";
import { ShepherdTourContext } from "react-shepherd";
import Utils from "../../../components/Utils";
import api from "../../../utils/api";
import { PostCategory } from "../../../utils/api/_type";
import { RubriqueSchema } from "../RubriqueForm";
import "../colorPickerStyle.css";

import style from "../RubriqueForm.module.css";

interface Props {
  value: PostCategory;
  setValue: React.Dispatch<React.SetStateAction<PostCategory>>;
  setShowUpdateMessage: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Information({ value, setValue, setShowUpdateMessage }: Props) {
  const { mutate: updateCategory } = useMutation(api.categories.updateCategory, {
    onSuccess: () => {
      setShowUpdateMessage(true);
    },
  });

  const tour = useContext(ShepherdTourContext);

  const [haveDebounceName, setHaveDebounceName] = useState<undefined | NodeJS.Timeout>(undefined);
  function handleName(e: any) {
    setValue((b) => ({ ...b, name: e.target.value }));

    if (value.id !== undefined && e.target.value !== "") {
      if (haveDebounceName !== undefined) {
        clearTimeout(haveDebounceName as any);
      }

      let timout = setTimeout(() => {
        updateCategory({
          id: value.id as number,
          body: { name: e.target.value },
        });
      }, 500);

      setHaveDebounceName(timout);
    }
  }

  const [haveDebounceDescription, setHaveDebounceDescription] = useState<undefined | NodeJS.Timeout>(undefined);
  function handleDescription(e: any) {
    setValue((b) => ({ ...b, description: e.target.value }));

    if (value.id !== undefined) {
      if (haveDebounceDescription !== undefined) {
        clearTimeout(haveDebounceDescription as any);
      }

      let timout = setTimeout(() => {
        updateCategory({
          id: value.id as number,
          body: { description: e.target.value },
        });
      }, 500);

      setHaveDebounceDescription(timout);
    }
  }

  const [haveDebounceColor, setHaveDebounceColor] = useState<undefined | NodeJS.Timeout>(undefined);
  function handleColor(e: string, nb = 1) {
    let body = {};
    switch (nb) {
      case 1:
        body = { color: e.replaceAll("#", "") };
        setValue((b) => ({ ...b, color: e.replaceAll("#", "") }));
        break;
      case 2:
        body = { color2nd: e.replaceAll("#", "") };
        setValue((b) => ({ ...b, color2nd: e.replaceAll("#", "") }));
        break;
    }

    if (value.id !== undefined && e !== "") {
      if (haveDebounceColor !== undefined) {
        clearTimeout(haveDebounceColor as any);
      }
      let timout = setTimeout(() => {
        updateCategory({
          id: value.id as number,
          body,
        });
      }, 500);

      setHaveDebounceColor(timout);
    }
  }

  useEffect(() => {
    if (tour?.isActive())
      setValue((b) => {
        return { ...b, name: b.name === "" ? "Ma première rubrique" : b.name };
      });
  }, []);

  useEffect(() => {
    // value.color && setColor(`#${value.color}`);
  }, [value]);

  return (
    <div
      className="onboarding-categories-3rd"
      style={{
        width: "100%",
        maxWidth: "600px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Utils.Input
        value={value.name}
        onChange={handleName}
        label="Nom de la rubrique"
        // helpIcon={faQuestionCircle}
        // helpContent="Nom de la rubrique que vous créez."
        isInvalid={!RubriqueSchema.fields.name.isValidSync(value.name)}
      />
      <div style={{ marginTop: "2rem" }} />
      <Utils.Textarea
        value={value.description}
        // helpIcon={faQuestionCircle}
        // helpContent="Description de la rubrique que vous créez."
        placeholder="Optionnel"
        onChange={handleDescription}
        name="description"
        label="Description"
        rows={3}
      />
      <div className={style.switchContainer}>
        <Utils.Switch
          checked={!!value.showLogo}
          onClick={() => {
            value.id && updateCategory({ id: value.id as number, body: { showLogo: !value.showLogo } });
            setValue((b) => ({ ...b, showLogo: !value.showLogo }));
          }}
        />
        <div className={style.switchLabel}>Afficher le logo de l'entreprise</div>
      </div>
      <div className={style.colorPickersContainer}>
        <div className={style.colorPickersColumn}>
          <Utils.Input
            value={`#${value.color || "3668AF"}`}
            onChange={(e) => handleColor(e.target.value)}
            label="Couleur primaire"
            helpTitle={"Couleur primaire"}
            helpContent="Couleur utilisée pour les titres et les boutons."
          />
          <div className={style.colorPickersDivider} />
          <div
            className={style.colorPickersPreviewDiv}
            style={{
              backgroundColor: `#${value.color ? value.color.replaceAll("#", "") : "3668AF"}`,
            }}
          />
          <HexColorPicker color={`#${value.color || "3668AF"}`} onChange={handleColor} />
        </div>

        <div className={style.colorPickersColumn}>
          <Utils.Input
            value={`#${value.color2nd || "F2F4F9"}`}
            onChange={(e) => handleColor(e.target.value, 2)}
            label="Couleur secondaire"
            helpTitle={"Couleur secondaire"}
            helpContent="Couleur utilisée pour l'arrière plan."
          />
          <div className={style.colorPickersDivider} />
          <div
            className={style.colorPickersPreviewDiv}
            style={{
              backgroundColor: `#${value.color2nd ? value.color2nd.replaceAll("#", "") : "F2F4F9"}`,
            }}
          />
          <HexColorPicker color={`#${value.color2nd || "F2F4F9"}`} onChange={(e) => handleColor(e, 2)} />
        </div>
      </div>
    </div>
  );
}
