import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faBug } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, ReactNode } from "react";

interface PatchNote {
  title: string;
  previews: ReactNode[];
  content: ReactNode;
}

interface Tag {
  label: string;
  color: string;
}

class TagType {
  static readonly NEW: Tag = { label: "Nouveauté", color: "var(--green)" };
  static readonly UPDATE: Tag = { label: "Amélioration", color: "var(--primary-color)" };
  static readonly CORRECTED: Tag = { label: "Correction", color: "var(--light-red)" };
}

const newsStyle: CSSProperties = {
  padding: "0.2em 0.6em 0.3em",
  borderRadius: "12px",
  fontWeight: "500",
  color: "var(--background)",
};

const newsLightStyle: CSSProperties = {
  height: "25px",
  width: "25px",
  borderRadius: "50%",
  display: "inline-block",
};

const ulStyle: CSSProperties = {
  listStyle: "none",
  padding: 0,
  margin: "1rem",
};

const liStyle: CSSProperties = {
  marginBottom: "1rem",
};

const titleStyle: CSSProperties = {
  fontWeight: 600,
};

const Divider = () => {
  return <div style={{ marginBottom: "0.5rem" }} />;
};

const Nametag = ({ title, type, lightFormat }: { title: string; type: Tag; lightFormat?: boolean }) => {
  const tagStyle: CSSProperties = lightFormat ? newsLightStyle : newsStyle;
  return (
    <span style={lightFormat ? { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" } : {}}>
      <span style={{ backgroundColor: type.color, ...tagStyle }}>{lightFormat ? " " : type.label}</span>{" "}
      <span style={lightFormat ? {} : titleStyle}>{title}</span>
    </span>
  );
};

export const patchNotes: PatchNote[] = [
  {
    title: "Mise à jour du 18/09/2023",
    previews: [<Nametag title="Améliorations du champ titre" type={TagType.UPDATE} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />

        <li style={liStyle}>
          <Nametag title="Améliorations du champ titre" type={TagType.UPDATE} lightFormat />
          <Divider />
          <span>Le champ titre permet dorénavant d'y ajouter une description qui sera visible sur le formulaire.</span>
          <Divider />
          <span>L'objectif étant de placer des informations entre les différents champs.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 24/08/2023",
    previews: [<Nametag title="Corrections" type={TagType.CORRECTED} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />

        <li style={liStyle}>
          <Nametag title="Corrections" type={TagType.CORRECTED} lightFormat />
          <Divider />
          <span>Les type nécessitant la création de choix ont maintenant un auto focus pour accélérer la création de ceux-ci.</span>
          <Divider />
          <span>Renommer les choix de ces types est aussi plus rapide car il n'est plus nécessaire de réécrire tout le nom.</span>
          <Divider />
          <span>Enfin, le type échelle gère mieux les choix trop long.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 13/07/2023",
    previews: [<Nametag title="Export CSV" type={TagType.UPDATE} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />

        <li style={liStyle}>
          <Nametag title="Export CSV" type={TagType.UPDATE} lightFormat />
          <Divider />
          <span>
            L'exportation d'une rubrique en CSV était incorrect car cette fonctionnalité n'était pas compatible avec le fait de modifier la position
            des champs. C'est maintenant fonctionnel.
          </span>
          <Divider />
          <span>Il est maintenant possible d'exporter les réponses en CSV en choisissant une intervalle de temps.</span>
          <Divider />
          <span>
            Dorénavant, si vous supprimez un champs d'un questionnaire, les précédentes réponses utilisant ce champs ne seront plus supprimées.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 19/06/2023",
    previews: [<Nametag title="Correction de bugs" type={TagType.CORRECTED} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />

        <li style={liStyle}>
          <Nametag title="Correction de bugs" type={TagType.CORRECTED} lightFormat />
          <Divider />
          <span>Le tri des dates de création n'étaient pas correct.</span>
          <Divider />
          <span>Le bouton actif/inactif ne fonctionnait pas lorsque l'on venait de créer une rubrique.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 06/06/2023",
    previews: [<Nametag title="Tri des rubriques" type={TagType.NEW} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />

        <li style={liStyle}>
          <Nametag title="Tri des rubriques" type={TagType.NEW} />
          <Divider />
          <span>Vous pouvez dorénavant trier vos rubriques selon leur statut leur nom, leur créateur ou leur date.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 05/06/2023",
    previews: [<Nametag title="Status de rubrique" type={TagType.NEW} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />

        <li style={liStyle}>
          <Nametag title="Status de rubrique" type={TagType.NEW} />
          <Divider />
          <span>
            Les rubriques peuvent maintenant être actives ou inactives. Cette fonctionnalité est en bétà et devrait prochaînement être améliorée avec
            l'arrivé de possibilités de tri des rubriques.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 15/05/2023",
    previews: [<Nametag title="Correction de bug sur les CSV" type={TagType.CORRECTED} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />

        <li style={liStyle}>
          <Nametag title="Correction de bug sur les CSV" type={TagType.CORRECTED} />
          <Divider />
          <span>
            Les accents dans l'entête du CSV sont maintenant gérés. De plus, l'ordre des colonnes est maintenant en corrélation avec celui des champs,
            même si cet ordre a été modifié.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 12/04/2023",
    previews: [
      <Nametag title='Champ "Échelle"' type={TagType.NEW} lightFormat />,
      <Nametag title='Champ "titre"' type={TagType.NEW} lightFormat />,
      <Nametag title="Couleur primaire/secondaire" type={TagType.UPDATE} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title='Champ "Échelle"' type={TagType.UPDATE} />
          <Divider />
          <span>
            Le type de champ "Échelle" est désormais disponible. Il permet de demander une note à vos utilisateurs selon une échelle que vous avez
            vous-même défini.
          </span>
        </li>

        <li style={liStyle}>
          <Nametag title='Champ "titre"' type={TagType.UPDATE} />
          <Divider />
          <span>
            Le type de champ "titre" est désormais disponible. Il permet d'ajouter des titres entre les différents champs, sans attendre une réponse
            en dessous.
          </span>
        </li>

        <li style={liStyle}>
          <Nametag title="Couleur primaire/secondaire" type={TagType.UPDATE} />
          <Divider />
          <span>Les catégories comportent maintenant une couleur primaire (titres, boutons ect..) et une couleur secondaire (fond).</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 21/03/2023",
    previews: [<Nametag title="Tableau d'étiquettes virtuelles" type={TagType.UPDATE} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Tableau d'étiquettes virtuelles" type={TagType.UPDATE} />
          <Divider />
          <span>
            Le tableau d'étiquettes virtuelles s'est vu amélioré. La recherche parmis les propositions du tableau est désormais possible. De plus, la
            barre du haut de page a été revue être moins intrusive et plus utile. La compatibilité du tableau sur petits écrans et mobiles a été
            améliorée.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 13/03/2023",
    previews: [
      <Nametag title="Filtre par réponses" type={TagType.NEW} lightFormat />,
      <Nametag title="Champs dédiés aux admins" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Optimisation de l'application" type={TagType.CORRECTED} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Filtre par réponses" type={TagType.NEW} />
          <Divider />
          <span>La recherche dans la liste des propositions prend maintenant en compte le contenu des réponses.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Champs dédiés aux admins" type={TagType.UPDATE} />
          <Divider />
          <span>
            Les champs dédiés aux administrateurs sont de nouveau actifs. Ces champs sont visibles uniquement par les admins et permettent de laisser
            des messages supplémentaires sur une proposition.
          </span>
          <Divider />
          <span>Ces champs sont présent lors de la création d'une proposition, mais sont aussi éditables depuis la liste des propositions.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Optimisation de l'application" type={TagType.CORRECTED} />
          <Divider />
          <span>Plusieurs parties de l'application ont été légèrement optimisées.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 01/03/2023",
    previews: [<Nametag title="Correction de bug" type={TagType.CORRECTED} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Correction de bug" type={TagType.CORRECTED} />
          <Divider />
          <span>Un bug d'actualisation du statut des propositions a été corrigé.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 25/01/2023",
    previews: [
      <Nametag title="Intervalles de dates" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Correction de bugs" type={TagType.CORRECTED} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Intervalles de dates" type={TagType.UPDATE} />
          <Divider />
          <span>Vous pouvez maintenant trier les propositions par intervalle de dates.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Correction de bugs" type={TagType.CORRECTED} />
          <Divider />
          <span>Plusieurs bugs ont été corrigés :</span>
          <ul>
            <li>Le compteur des propositions affichait le nombre de proposition chargées à l'écran, et non le nombre réel,</li>
            <li>
              Lors de la création d'une rubrique le bouton{" "}
              <span
                style={{ color: "var(--black)", backgroundColor: "var(--secondary-color)", fontWeight: 600, borderRadius: "5px", padding: "2px" }}
              >
                Terminer
              </span>{" "}
              ne ramenait pas dans la liste des rubriques dans certains cas,
            </li>
            <li>Les menus déroulants ne se fermaient pas si on cliquait en dehors,</li>
            <li>
              Les tutoriels participatifs sont masqués pour les utilisateurs ne possèdants pas le rôle <span style={{ fontWeight: 600 }}>Admin</span>.
            </li>
          </ul>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 03/11/2022",
    previews: [
      <Nametag title="Notifications" type={TagType.NEW} lightFormat />,
      <Nametag title="Correction de bug" type={TagType.CORRECTED} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Notifications" type={TagType.NEW} />
          <Divider />
          <span>
            Vous pouvez dorénavant assigner à certains de vos utilisateurs la réception de notifications lorsqu'une proposition est créée sur une
            catégorie donnée.
          </span>
          <Divider />
          <span>
            Vous avez aussi la possibilité de choisir si vos utilisateurs seront notifiés lorsque vous répondrez à ou changerez le statut de leur
            proposition. Si l'utilisateur est anonyme ou ne dispose pas d'adresse mail défini, la notification ne sera pas envoyée.
          </span>
        </li>

        <li style={liStyle}>
          <Nametag title="Correction de bug" type={TagType.CORRECTED} />
          <Divider />
          <span>
            Correction d'un bug d'affichage dans la fenêtre de réponse aux propositions. Lorsque l'ont répondait à une proposition, elle était
            déselectionnée et plus rien n'était affiché.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 27/10/2022",
    previews: [
      <Nametag title="Améliorations visuelles" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Réponses des propositions" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Tableau d'étiquettes" type={TagType.UPDATE} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Améliorations visuelles" type={TagType.UPDATE} />
          <Divider />
          <span>
            Diverses améliorations visuelles on été éfféctuées sur les pages de création/modification d'une rubrique, de réponses à une rubrique, et
            sur le tabeau d'étiquette virtuel.
          </span>
        </li>

        <li style={liStyle}>
          <Nametag title="Réponses des propositions" type={TagType.UPDATE} />
          <Divider />
          <span>Vous pouvez maintenant répondre a une proposition directement depuis la liste en cliquant sur répondre.</span>
          <Divider />
          <span>
            L'ancienne fenêtre permettant de voir la proposition en détail est maintenant accessible en cliquant sur la proposition. Cette fenêtre a
            aussi été améliorée.
          </span>
        </li>

        <li style={liStyle}>
          <Nametag title="Tableau d'étiquettes" type={TagType.UPDATE} />
          <Divider />
          <span>
            Plus d'informations sont affichées sur les étiquettes et les champs réservés aux administrateurs sont cachés pour les personnes n'ayant
            pas l'accès.
          </span>
          <Divider />
          <span>
            Sachez que si vous décidez de définir un champ réservé en tant que champ favori, les autres utilisateurs verront une étiquette vide.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 25/10/2022",
    previews: [
      <Nametag title="Personnalisation" type={TagType.NEW} lightFormat />,
      <Nametag title="Statut archivée" type={TagType.NEW} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Personnalisation" type={TagType.NEW} />
          <Divider />
          <span>Vous pouvez maintenant personnaliser vos rubriques en leur donnant une couleur et en affichant le logo de votre entreprise.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Statut archivée" type={TagType.NEW} />
          <Divider />
          <span>
            Les nouvelles propositions peuvent maintenant être archivées, ce qui les rend invisibles dans le tableau d'étiquettes virtuel mais non
            supprimées.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 12/10/2022",
    previews: [<Nametag title="Correction d'orthographe" type={TagType.CORRECTED} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Correction d'orthographe" type={TagType.CORRECTED} />
          <Divider />
          <span>Certaines erreurs ont été corrigées.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 31/08/2022",
    previews: [<Nametag title="Harmonisation de l'apparence" type={TagType.UPDATE} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Harmonisation de l'apparence" type={TagType.UPDATE} />
          <Divider />
          <span>L'apparence de l'application a été harmonisée avec celle des autres applications.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 04/08/2022",
    previews: [<Nametag title="Ajout des photos" type={TagType.NEW} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Ajout des photos" type={TagType.NEW} />
          <Divider />
          <span>Si vous possédez Photos & Vidéos, vous pourrez ajouter des champs de type photo dans vos rubriques.</span>
          <Divider />
          <span>Cela permet aux utilisateurs de prendre des photos lors de la saisie d'une proposition.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 18/07/2022",
    previews: [
      <Nametag title="Détails des propositions dans le tableau d'étiquettes virtuelles" type={TagType.UPDATE} lightFormat />,
      <Nametag title="Liste des mises à jours" type={TagType.UPDATE} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Détails des propositions dans le tableau d'étiquettes virtuelles" type={TagType.UPDATE} />
          <Divider />
          <span>
            En cliquant sur une proposition dans le tableau d'étiquettes virtuelles il est maintenant possible de voir les détails de celle-ci.
          </span>
          <Divider />
          <span>Cela permet aux utilisateurs de connaître tous les champs et la réponse d'une proposition.</span>
        </li>

        <li style={liStyle}>
          <Nametag title="Liste des mises à jours" type={TagType.UPDATE} />
          <Divider />
          <span>La liste des mises à jour à eu le droit à une nouvelle apparence ! Elle est maintenant plus détaillée et plus lisible.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 12/07/2022",
    previews: [
      <Nametag title="Remonter un bug" type={TagType.CORRECTED} lightFormat />,
      <Nametag title="Orthographe dans les tutoriels participatifs" type={TagType.CORRECTED} lightFormat />,
    ],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Remonter un bug" type={TagType.CORRECTED} />
          <Divider />
          <span>
            Le bouton{" "}
            <span style={{ fontWeight: 600 }}>
              <FontAwesomeIcon icon={faBug} />
              Remonter un bug
            </span>{" "}
            pouvait ne pas s'afficher au bon endroit dans le menu de navigation.
          </span>
        </li>

        <li style={liStyle}>
          <Nametag title="Orthographe dans les tutoriels participatifs" type={TagType.CORRECTED} />
          <Divider />
          <span>Nous avons corrigé certaines fautes qui se trouvaient dans les étapes des tutoriels participatifs.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 15/06/2022",
    previews: [<Nametag title="Ajout des tutoriels intéractifs" type={TagType.NEW} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Ajout des tutoriels intéractifs" type={TagType.NEW} />
          <Divider />
          <span>
            Accessibles depuis la page d'accueil et depuis le bouton <FontAwesomeIcon color="var(--primary-color)" icon={faQuestionCircle} />, les
            tutoriels intéractifs vous permettent de découvrir les différentes fonctionnalités du site.
          </span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 20/05/2022",
    previews: [<Nametag title="Améliorations des menus déroulants" type={TagType.UPDATE} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Améliorations des menus déroulants" type={TagType.UPDATE} />
          <Divider />
          <span>Il vous est maintenant possible de choisir l'ordre des choix dans les menus déroulants de vos catégories.</span>
        </li>
      </ul>
    ),
  },
  {
    title: "Mise à jour du 17/05/2022",
    previews: [<Nametag title="Page d'accueil" type={TagType.NEW} lightFormat />],
    content: (
      <ul style={ulStyle}>
        <Divider />
        <li style={liStyle}>
          <Nametag title="Page d'accueil" type={TagType.NEW} />
          <Divider />
          <span>
            Nos applications disposent désormais d'une page d'accueil. Depuis cette page, vous aurez accès à la liste des mises à jour et à différents
            autres modules en fonction de l'application.
          </span>
          <Divider />
          <span>
            D'autres modules sont en cours de conception, n'hésitez pas à suivre les mises à jour afin d'être au courant de leur avancement.
          </span>
        </li>
      </ul>
    ),
  },
];
