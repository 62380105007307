import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient, useInfiniteQuery } from "react-query";
import api from "../../utils/api";
import Utils from "../../components/Utils";
import { faEye, faSearch, faTrash, faTh, faBars, faChalkboardTeacher } from "@fortawesome/free-solid-svg-icons";
import { Answer, AnswerItem, Field } from "../../utils/api/_type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dateToString, dateToDatabaseString, MY_DOMAIN, useDebounce, useFormState } from "../../utils/utils";
import { FILTER_OPTION } from "../../components/Utils/Sort/Sort";
import * as Yup from "yup";
import classNames from "classnames";
import { UserContextType, useUser } from "../../utils/context/User";
import { ShepherdTourContext } from "react-shepherd";
import ProposalComponent from "./ProposalComponent";
import ModalAnswer from "./ModalAnswer";

import style from "./ListProposals.module.css";
import { FIELDS_TYPE } from "../../utils/constantes";

export type FormInputObject = { [key: string]: string | boolean };

export default function ListProposals(): React.ReactElement {
  const tour = useContext(ShepherdTourContext);

  const queryClient = useQueryClient();

  const [user] = useUser() as UserContextType;

  // Récupération des données
  const { data: categories, isLoading: isLoadingCategories } = useQuery(["categories"], api.categories.getCategories, {
    keepPreviousData: true,
  });

  const initialFiltersValue = {
    global: "",
    category: {
      value: [],
      sort: null,
    },
    status: {
      value: ["En attente", "En cours"],
      sort: null,
    },
    creator: {
      value: "",
      sort: null,
    },
    createdAtFrom: {
      value: "",
      sort: "DESC" as FILTER_OPTION,
    },
    createdAtTo: {
      value: "",
      sort: "DESC" as FILTER_OPTION,
    },
    responseBy: {
      value: "",
      sort: null,
    },
  };

  const [filters, setFilters] = useState<{
    global: string;
    category: {
      value: string[];
      sort: FILTER_OPTION;
    };
    status: {
      value: string[];
      sort: FILTER_OPTION;
    };
    creator: {
      value: string;
      sort: FILTER_OPTION;
    };
    createdAtFrom: {
      value: string;
      sort: FILTER_OPTION;
    };
    createdAtTo: {
      value: string;
      sort: FILTER_OPTION;
    };
    responseBy: {
      value: string;
      sort: FILTER_OPTION;
    };
  }>(initialFiltersValue);

  const [answers, setAnswers] = useState<Answer[]>([]);
  const [totalAnswerLength, setTotalAnswerLength] = useState<number>(0);

  const { isLoading, hasNextPage, fetchNextPage, isFetching } = useInfiniteQuery(
    ["answers", filters],
    ({ pageParam = 1 }) => api.answers.getAllAnswers({ page: pageParam, filters }),
    {
      onSuccess: (answersPagination) => {
        if (answersPagination === undefined || answersPagination.pages === undefined) setAnswers([]);
        setTotalAnswerLength(answersPagination.pages[0].pagination.totalCount || 0);

        setAnswers(answersPagination.pages.map((page) => page.items).flat());
      },
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.pagination.current < lastPage.pagination.endPage) {
          return lastPage.pagination.current + 1;
        } else {
          return undefined;
        }
      },
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    window.addEventListener("scroll", eventHandler);

    function eventHandler() {
      if (document.documentElement.scrollHeight - document.documentElement.scrollTop <= window.innerHeight + 300 && hasNextPage && !isFetching) {
        fetchNextPage();
      }
    }

    return () => {
      window.removeEventListener("scroll", eventHandler);
    };
  }, [fetchNextPage, hasNextPage, isFetching]);

  function getCreator(answer: Answer) {
    if (answer.creator !== null) {
      return (
        <>
          {answer.creator.firstname} {answer.creator.lastname}
        </>
      );
    } else if (answer.freeField !== null) {
      return <>{answer.freeField}</>;
    } else {
      return "Anonyme";
    }
  }

  /* Filtres */
  const [searchValue, setSearchValue] = useDebounce("", (val) => {
    setFilters((old) => ({ ...old, global: val }));
  });
  const [creatorFilter, setCreatorFilter] = useDebounce("", (val) => {
    setFilters((old) => ({ ...old, creator: { ...old.creator, value: val } }));
  });
  const [responesByFilter, setResponseByFilter] = useDebounce("", (val) => {
    setFilters((old) => ({
      ...old,
      responseBy: { ...old.responseBy, value: val },
    }));
  });

  function handleClickCategory(e: any, categoryName: string) {
    if (e.target.checked) {
      setFilters((old) => ({
        ...old,
        category: {
          ...old.category,
          value: [...old.category.value, categoryName],
        },
      }));
    } else {
      setFilters((old) => ({
        ...old,
        category: {
          ...old.category,
          value: old.category.value.filter((categoryInFilter) => categoryInFilter !== categoryName),
        },
      }));
    }
  }

  function handleClickStatus(e: any, statusName: string) {
    if (e.target.checked) {
      setFilters((old) => ({
        ...old,
        status: {
          ...old.status,
          value: [...old.status.value, statusName],
        },
      }));
    } else {
      setFilters((old) => ({
        ...old,
        status: {
          ...old.status,
          value: old.status.value.filter((statusInFilter) => statusInFilter !== statusName),
        },
      }));
    }
  }

  function resetFilters() {
    setFilters(initialFiltersValue);
    setCreatorFilter("");
    setResponseByFilter("");
  }
  /* Fin filtres */

  const [isOpenedProposalModalOpen, setIsOpenedProposalModalOpen] = useState<boolean>(false);
  const [openedProposal, setOpenedProposal] = useState<undefined | Answer>(undefined);
  const [answerResponse, setAnswerResponse] = useState("");
  const [answerStatus, setAnswerStatus] = useState<undefined | number>();

  function openProposal(answer: Answer) {
    if (openedProposal !== undefined && openedProposal.id === answer.id) return;
    let validationObject: { [key: string]: Yup.BaseSchema } = {};
    let form: FormInputObject = {};
    answer.answerItems
      .filter((answerItem) => answerItem.field.required === "pilote")
      .forEach((answerItem) => {
        validationObject[answerItem.field.id + ""] = generateSchema(answerItem.field);
        form[answerItem.field.id + ""] = generateObject(answerItem);
      });

    setValidationSchema(Yup.object().shape(validationObject));
    resetForm(form);
    setOpenedProposal(answer);
    setIsOpenedProposalModalOpen(true);
  }

  const [displayMode, setDisplayMode] = useState<"grid" | "row">("grid");

  const { mutate: updateAnswer } = useMutation(api.answers.updateAnswer, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries("answers");
      if (openedProposal !== undefined) setOpenedProposal(data);
    },
  });

  function changeProposalStatus(value: any, idAnswer: number) {
    setAnswerStatus(parseInt(value));
    updateAnswer({
      answerId: idAnswer,
      statusId: parseInt(value),
    });
  }

  const { mutate: response, isLoading: isLoadingAddResponse } = useMutation(api.answers.addResponse, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("answers");
      // if (openedProposal !== undefined) setOpenedProposal(undefined);
    },
  });

  function handleResponse() {
    if (openedProposal === undefined) return;

    const fields = openedProposal.answerItems.map((aI) => aI.field);

    let copy = structuredClone(formState);

    Object.entries(formState).map(([key, value]) => {
      let type = fields.find((f) => f.id === parseInt(key))?.type;

      let res = formatValue(value, type);

      copy[key] = res;
    });

    response({
      answerId: openedProposal.id,
      response: answerResponse,
      fields: copy,
    });
  }

  function formatValue(value: string | boolean, type?: FIELDS_TYPE): string | boolean {
    if (typeof value === "boolean") return value;

    if (typeof value === "object") return value;

    if (type === undefined) return value;
    if (value === "") return "";

    if (type === "date") return dateToDatabaseString(new Date(value));
    else return value;
  }

  // Affichage du fields en fonction de son type
  function formatAnswerItem(answerItem?: AnswerItem) {
    if (answerItem) {
      switch (answerItem.type) {
        case "select":
        case "likert":
        case "int":
        case "string":
          return answerItem.value || "-";
        case "date":
          return answerItem.value ? dateToString(answerItem.value) : "-";
        case "bool":
          return <Utils.Checkbox checked={answerItem.value === "1"} readOnly />;
        case "file":
          return (
            <>
              {answerItem.value.length <= 0 ? (
                "-"
              ) : (
                <img className={style.img} alt=" Cette image n'existe pas." src={answerItem.value} onClick={() => window.open(answerItem.value)} />
              )}
            </>
          );
        case "title":
          return (
            <span style={{ fontStyle: "italic", opacity: "0.6", color: answerItem.field.required === "pilote" ? "var(--red)" : "var(--black)" }}>
              {answerItem.field.properties.description}
            </span>
          );
      }
    }
  }

  // const [isLoading, setIsLoading] = useState<boolean>(false);

  // Suppression de proposition
  const { mutate: deleteAnwser, isLoading: isLoadingDeleteResponse } = useMutation(api.answers.deleteAnswer, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries("answers");
      setOpenedDelete(undefined);
    },
  });

  const [openedDelete, setOpenedDelete] = useState<undefined | Answer>(undefined);
  // Fin Suppression

  const [validationSchema, setValidationSchema] = useState(Yup.object().shape({}));
  const [formState, handleInput, resetForm] = useFormState<FormInputObject>({});

  function generateSchema(f: Field): Yup.AnySchema {
    switch (f.type) {
      case "bool":
        return Yup.bool();
      case "date":
        return Yup.string();
      case "int":
        return Yup.string();
      case "string":
        return Yup.string();
      case "select":
        return Yup.mixed();
      default:
        return Yup.mixed();
    }
  }

  function generateObject(answerItem: AnswerItem): string | boolean {
    return answerItem.value;
  }

  function onClickOnRow(elementClicked: Element, answer: Answer) {
    if (elementClicked.tagName === "TD") {
      openProposal(answer);
    }
  }

  function getCardButtons(): React.ReactNode {
    return (
      <span>
        <Utils.Button
          id="onboarding-virtualboard-next-2nd"
          className="onboarding-virtualboard-2nd"
          format="square"
          containerStyle={{ marginTop: "0px" }}
          onClick={() => {
            if (tour && tour.isActive()) {
              tour.complete();
              window.open("/proposal/" + user?.company.token + "?onBoarding=true");
            } else {
              window.open("/proposal/" + user?.company.token);
            }
          }}
        >
          Tableau d'étiquettes virtuelles <FontAwesomeIcon icon={faChalkboardTeacher} />
        </Utils.Button>
      </span>
    );
  }

  function formatColor(answer: Answer) {
    if (answer.status.name === "En attente") {
      return style.secondary;
    } else if (answer.status.name === "En cours") {
      return style.primary;
    } else if (answer.status.name === "Retenue") {
      return style.green;
    } else if (answer.status.name === "Non-retenue") {
      return style.red;
    } else if (answer.status.name === "Archivée") {
      return style.gray;
    }
  }

  useEffect(() => {
    setAnswerResponse(openedProposal?.response || "");
  }, [openedProposal]);

  return (
    <Utils.Container verticalCenter={false}>
      <Utils.Card title="Propositions" width="100%" isLoading={isLoading} leftElements={getCardButtons()}>
        <div className="onboarding-pushidea-6th">
          <Utils.TopContainer>
            <div style={{ marginTop: "10px" }}>
              <Utils.Input
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                inverted={true}
                icon={faSearch}
                placeholder="Rechercher"
                width="250px"
                clear={() => {
                  setSearchValue("");
                }}
              />
            </div>
            <Utils.HorizontalContainer style={{ justifyContent: "space-between", width: "100%" }}>
              <Utils.ImportantNumber style={{ marginLeft: "20px" }} word="Proposition" number={totalAnswerLength} />
              <div>
                <FontAwesomeIcon
                  title="Affichage mosaïque"
                  icon={faTh}
                  style={{ marginRight: "10px" }}
                  className={classNames(style.icon, {
                    [style.selectedIcon]: displayMode === "grid",
                  })}
                  onClick={() => setDisplayMode("grid")}
                />
                <FontAwesomeIcon
                  title="Affichage en liste"
                  icon={faBars}
                  className={classNames(style.icon, {
                    [style.selectedIcon]: displayMode === "row",
                  })}
                  onClick={() => setDisplayMode("row")}
                />
              </div>
            </Utils.HorizontalContainer>
          </Utils.TopContainer>

          <div className={style.twoColumns}>
            <Utils.SortMenu className="onboarding-detailledproposals-2nd" title="Affiner la recherche" clear={() => resetFilters()}>
              <>
                <Utils.SortMenuRow title="Rubriques">
                  {isLoadingCategories || categories === undefined ? (
                    <Utils.Spinner center />
                  ) : (
                    categories.map((category) => (
                      <Utils.Checkbox
                        key={category.id}
                        label={category.name}
                        checked={filters.category.value.includes(category.name)}
                        onClick={(e) => handleClickCategory(e, category.name)}
                      />
                    ))
                  )}
                </Utils.SortMenuRow>
                <Utils.SortMenuRow title="Statut">
                  <Utils.Checkbox
                    label="En attente"
                    checked={filters.status.value.includes("En attente")}
                    onClick={(e) => handleClickStatus(e, "En attente")}
                  />
                  <Utils.Checkbox
                    label="En cours"
                    checked={filters.status.value.includes("En cours")}
                    onClick={(e) => handleClickStatus(e, "En cours")}
                  />
                  <Utils.Checkbox
                    label="Retenue"
                    checked={filters.status.value.includes("Retenue")}
                    onClick={(e) => handleClickStatus(e, "Retenue")}
                  />
                  <Utils.Checkbox
                    label="Non-retenue"
                    checked={filters.status.value.includes("Non-retenue")}
                    onClick={(e) => handleClickStatus(e, "Non-retenue")}
                  />
                  <Utils.Checkbox
                    label="Archivée"
                    checked={filters.status.value.includes("Archivée")}
                    onClick={(e) => handleClickStatus(e, "Archivée")}
                  />
                </Utils.SortMenuRow>
                <Utils.SortMenuRow title="Auteur">
                  <Utils.Input
                    value={creatorFilter}
                    onChange={(e) => setCreatorFilter(e.target.value)}
                    colorReverse
                    placeholder="Rechercher"
                    clear={() => {
                      setCreatorFilter("");
                    }}
                  />
                </Utils.SortMenuRow>
                <Utils.SortMenuRow title="Date de la demande">
                  <Utils.Input
                    placeholder="Date du"
                    type="text"
                    onFocus={(e) => (e.target.type = "date")}
                    colorReverse
                    value={filters.createdAtFrom.value}
                    style={filters.createdAtFrom.value.length <= 0 ? { color: "var(--secondary-text)" } : {}}
                    onChange={(e) =>
                      setFilters((b) => ({
                        ...b,
                        createdAtFrom: { ...b.createdAtFrom, value: e.target.value },
                      }))
                    }
                  />
                  <Utils.Input
                    placeholder="Au"
                    type="text"
                    onFocus={(e) => (e.target.type = "date")}
                    colorReverse
                    value={filters.createdAtTo.value}
                    style={filters.createdAtTo.value.length <= 0 ? { color: "var(--secondary-text)" } : {}}
                    onChange={(e) =>
                      setFilters((b) => ({
                        ...b,
                        createdAtTo: { ...b.createdAtTo, value: e.target.value },
                      }))
                    }
                  />
                </Utils.SortMenuRow>
                <Utils.SortMenuRow title="Répondu par">
                  <Utils.Input
                    value={responesByFilter}
                    onChange={(e) => setResponseByFilter(e.target.value)}
                    colorReverse
                    placeholder="Rechercher"
                    clear={() => {
                      setResponseByFilter("");
                    }}
                  />
                </Utils.SortMenuRow>
              </>
            </Utils.SortMenu>
            <div className={style.rightPart}>
              {answers.length > 0 ? (
                <>
                  {displayMode === "row" ? (
                    <Utils.Table className={style.table}>
                      <thead>
                        <tr>
                          <th style={{ width: "15%" }}>
                            <Utils.Sort
                              order={filters.category.sort}
                              onChangeOrder={(order) =>
                                setFilters((b) => ({
                                  ...b,
                                  category: {
                                    ...b.category,
                                    sort: order,
                                  },
                                }))
                              }
                            >
                              Rubrique
                            </Utils.Sort>
                          </th>
                          <th style={{ width: "10px" }}>
                            <Utils.Sort
                              order={filters.status.sort}
                              onChangeOrder={(order) =>
                                setFilters((b) => ({
                                  ...b,
                                  status: {
                                    ...b.status,
                                    sort: order,
                                  },
                                }))
                              }
                            >
                              Statut
                            </Utils.Sort>
                          </th>
                          <th style={{ width: "40%" }}>Aperçu</th>
                          <th>
                            <Utils.Sort
                              order={filters.creator.sort}
                              onChangeOrder={(order) =>
                                setFilters((b) => ({
                                  ...b,
                                  creator: {
                                    ...b.creator,
                                    sort: order,
                                  },
                                }))
                              }
                            >
                              Auteur
                            </Utils.Sort>
                          </th>
                          <th>
                            <Utils.Sort
                              order={filters.createdAtFrom.sort}
                              onChangeOrder={(order) =>
                                setFilters((b) => ({
                                  ...b,
                                  createdAtFrom: {
                                    ...b.createdAtFrom,
                                    sort: order,
                                  },
                                }))
                              }
                            >
                              Créé le
                            </Utils.Sort>
                          </th>
                          {/* <th>
                        <Utils.Sort
                          order={filters.responseBy.sort}
                          onChangeOrder={(order) =>
                            setFilters((b) => ({
                              ...b,
                              responseBy: {
                                ...b.responseBy,
                                sort: order,
                              },
                            }))
                          }
                        >
                          Répondu par
                        </Utils.Sort>
                      </th> */}
                          <th style={{ width: "70px" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {answers.map((answer) => (
                          <tr
                            key={answer.id}
                            onClick={(e) => onClickOnRow(e.target as Element, answer)}
                            className={classNames(style.answerRow, formatColor(answer), "onboarding-detailledproposals-3rd")}
                          >
                            <td>{answer.category.name}</td>
                            <td>
                              <Utils.Select
                                value={answer.status.id}
                                onChange={(e) => changeProposalStatus(e, answer.id)}
                                className={classNames(style.select, "onboarding-detailledproposals-6th")}
                              >
                                {answer.category.status.map((status, optIndex) => (
                                  <option key={optIndex} value={status.id}>
                                    {status.name}
                                  </option>
                                ))}
                              </Utils.Select>
                            </td>
                            <td style={{ wordBreak: "break-word" }}>
                              {formatAnswerItem(answer.answerItems.find((answerItem) => answerItem.field.isFavorite))}
                            </td>
                            <td>{getCreator(answer)}</td>
                            <td>{dateToString(answer.createdAt)}</td>
                            {/* <td>
                          {answer.responseBy?.firstname}{' '}
                          {answer.responseBy?.lastname}
                        </td> */}
                            <td>
                              <FontAwesomeIcon
                                title="Afficher la proposition"
                                icon={faEye}
                                className={style.actionIcon}
                                onClick={() => openProposal(answer)}
                              />
                              <FontAwesomeIcon
                                title="Supprimer la poposition"
                                icon={faTrash}
                                className={classNames(style.actionIcon, "onboarding-detailledproposals-4th")}
                                onClick={() => setOpenedDelete(answer)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Utils.Table>
                  ) : (
                    <div className={style.gridContainer}>
                      {answers.map((answer) => (
                        <React.Fragment key={answer.id}>
                          <ProposalComponent
                            answer={answer}
                            getCreator={getCreator}
                            setOpenedDelete={setOpenedDelete}
                            formatColor={formatColor}
                            changeProposalStatus={changeProposalStatus}
                            formatAnswerItem={formatAnswerItem}
                            openProposal={openProposal}
                            answerResponse={answerResponse}
                            setAnswerResponse={setAnswerResponse}
                            openedProposal={openedProposal}
                            isOpenedProposalModalOpen={isOpenedProposalModalOpen}
                            setOpenedProposal={setOpenedProposal}
                            handleResponse={handleResponse}
                          />
                        </React.Fragment>
                      ))}
                      {answers.length % 3 === 2 && <div style={{ flexBasis: "32%" }}></div>}
                    </div>
                  )}
                </>
              ) : (
                <div className={style.empty} style={{ marginTop: "2.6429rem" }}>
                  <div className={style.emptyIcon} style={{ backgroundImage: `url("${MY_DOMAIN}/empty.svg")` }} />
                  <span>Vous n'avez aucune propositions.</span>
                  <span>Partagez vos rubriques pour en recevoir !</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Utils.Card>

      <ModalAnswer
        answers={answers}
        isOpenedProposalModalOpen={isOpenedProposalModalOpen}
        setIsOpenedProposalModalOpen={setIsOpenedProposalModalOpen}
        openedProposal={openedProposal}
        setOpenedProposal={setOpenedProposal}
        getCreator={getCreator}
        formState={formState}
        handleInput={handleInput}
        resetForm={resetForm}
        validationSchema={validationSchema}
        formatAnswerItem={formatAnswerItem}
        answerStatus={answerStatus}
        setAnswerStatus={setAnswerStatus}
        changeProposalStatus={changeProposalStatus}
        answerResponse={answerResponse}
        setAnswerResponse={setAnswerResponse}
        handleResponse={handleResponse}
        isLoadingAddResponse={isLoadingAddResponse}
      />

      <Utils.Modal isOpen={openedDelete !== undefined} onClose={() => setOpenedDelete(undefined)} closable title={"Suppression"}>
        <p>Voulez vous vraiment supprimer la proposition ?</p>
        <Utils.HorizontalContainer>
          <Utils.Button onClick={() => setOpenedDelete(undefined)} variant="gray">
            Annuler
          </Utils.Button>
          <Utils.Button isLoading={isLoadingDeleteResponse} onClick={() => deleteAnwser(openedDelete?.id || -1)} variant="red">
            Supprimer
          </Utils.Button>
        </Utils.HorizontalContainer>
      </Utils.Modal>
    </Utils.Container>
  );
}
