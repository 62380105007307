import React, { useEffect, useState } from "react";
import { InfiniteData, QueryClient, useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import api from "../utils/api";

import style from "./ListCategories.module.css";

import Utils from "../components/Utils";
import { faClone, faFileCsv, faPen, faSearch, faShareAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Category, ICategoriesFilter, PageItem, SmallCategory, SmallUser } from "../utils/api/_type";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dateToString } from "../utils/utils";
import { useHistory } from "react-router-dom";
import ModalShare from "../components/ModalShare/ModalShare";

import classNames from "classnames";
import Switch from "../components/Utils/Switch/Switch";
import { FILTER_OPTION } from "../components/Utils/Sort/Sort";
import Modal from "../components/Utils/Modal/Modal";

const initialFiltersValue = {
  global: "",
  isActive: {
    value: "",
    sort: "DESC" as FILTER_OPTION,
  },
  category: {
    value: "",
    sort: null,
  },
  creator: {
    value: "",
    sort: null,
  },
  createdAt: {
    value: "",
    sort: "DESC" as FILTER_OPTION,
  },
};

export default function ListCategories(): React.ReactElement {
  const queryClient = useQueryClient();
  const history = useHistory();

  // Récupération des données
  const [filters, setFilters] = useState<ICategoriesFilter>(initialFiltersValue);

  const [categories, setCategories] = useState<Category[]>([]);
  const [totalCategoriesLength, setTotalCategoriesLength] = useState<number>(0);
  const { isLoading, hasNextPage, fetchNextPage, isFetching } = useInfiniteQuery(
    ["categories", filters],
    ({ pageParam = 1 }) => api.categories.getCategoriesFiltered({ page: pageParam, filters }),
    {
      onSuccess: (categoriesPagination) => {
        if (categoriesPagination === undefined || categoriesPagination.pages === undefined) setCategories([]);
        setTotalCategoriesLength(categoriesPagination.pages[0].pagination.totalCount || 0);

        setCategories(categoriesPagination.pages.map((page) => page.items).flat());
      },
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.pagination.current < lastPage.pagination.endPage) {
          return lastPage.pagination.current + 1;
        } else {
          return undefined;
        }
      },
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    // let isOnBoarding = qs.parse(window.location.search, { ignoreQueryPrefix: true }).onBoarding;
    // isOnBoarding === "true" && tour?.show("create-categories-2nd");
  }, []);

  // Handle delete category
  const [isModalDeleteCategoryOpen, setIsModalDeleteCategoryOpen] = useState<undefined | SmallCategory>(undefined);
  const { mutate: deleteCategory, isLoading: isLoadingDeleteResponse } = useMutation(api.categories.deleteCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries(["categories"]);
      setIsModalDeleteCategoryOpen(undefined);
    },
  });

  // Handle partage des catégories
  const [isModalShareOpen, setIsModalShareOpen] = useState<SmallCategory | undefined>(undefined);

  return (
    <Utils.Container verticalCenter={false}>
      <Utils.Card title="Rubriques" isLoading={isLoading} width="100%">
        <>
          <Utils.TopContainer>
            <Utils.Input placeholder="Rechercher" icon={faSearch} inverted />
            <Utils.ImportantNumber number={categories?.length || 0} word="Rubrique" />

            <Utils.Button
              className="onboarding-categories-2nd"
              onClick={() => history.push("/categories/create")}
              fullWidth={false}
              format="square"
              containerStyle={{ marginLeft: "auto" }}
            >
              Créer une rubrique
            </Utils.Button>
          </Utils.TopContainer>
          <Utils.Table className={style.table}>
            <thead>
              <tr>
                <th colSpan={1}>
                  <Utils.Sort
                    order={filters.isActive.sort}
                    onChangeOrder={(order) =>
                      setFilters((old) => ({
                        ...old,
                        isActive: {
                          ...old.isActive,
                          sort: order,
                        },
                      }))
                    }
                  >
                    Actif
                  </Utils.Sort>
                </th>
                <th colSpan={5}>
                  <Utils.Sort
                    order={filters.category.sort}
                    onChangeOrder={(order) =>
                      setFilters((b) => ({
                        ...b,
                        category: {
                          ...b.category,
                          sort: order,
                        },
                      }))
                    }
                  >
                    Nom de la rubrique
                  </Utils.Sort>
                </th>
                <th colSpan={2}>
                  <Utils.Sort
                    order={filters.creator.sort}
                    onChangeOrder={(order) =>
                      setFilters((b) => ({
                        ...b,
                        creator: {
                          ...b.creator,
                          sort: order,
                        },
                      }))
                    }
                  >
                    Créateur
                  </Utils.Sort>
                </th>
                <th colSpan={2}>
                  <Utils.Sort
                    order={filters.createdAt.sort}
                    onChangeOrder={(order) =>
                      setFilters((b) => ({
                        ...b,
                        createdAt: {
                          ...b.createdAt,
                          sort: order,
                        },
                      }))
                    }
                  >
                    Date de création
                  </Utils.Sort>
                </th>
                <th colSpan={3}></th>
              </tr>
            </thead>
            <tbody>
              {categories &&
                categories.map((category, index) => (
                  <React.Fragment key={index}>
                    <CategoryItem
                      category={category}
                      setIsModalShareOpen={setIsModalShareOpen}
                      queryClient={queryClient}
                      setIsModalDeleteCategoryOpen={setIsModalDeleteCategoryOpen}
                    />
                  </React.Fragment>
                ))}
            </tbody>
          </Utils.Table>
        </>
      </Utils.Card>

      <Utils.Modal
        isOpen={isModalDeleteCategoryOpen !== undefined}
        onClose={() => setIsModalDeleteCategoryOpen(undefined)}
        closable
        title={"Voulez vous vraiment supprimer la catégorie " + isModalDeleteCategoryOpen?.name + " ?"}
      >
        <>
          <p style={{ textAlign: "center" }}>Cela supprimera aussi toutes les propositions</p>
          <Utils.HorizontalContainer>
            <Utils.Button variant="gray" onClick={() => setIsModalDeleteCategoryOpen(undefined)}>
              Annuler
            </Utils.Button>
            <Utils.Button isLoading={isLoadingDeleteResponse} variant="red" onClick={() => deleteCategory(isModalDeleteCategoryOpen!.id)}>
              Supprimer
            </Utils.Button>
          </Utils.HorizontalContainer>
        </>
      </Utils.Modal>

      {isModalShareOpen !== undefined ? (
        <ModalShare isOpen={isModalShareOpen !== undefined} onClose={() => setIsModalShareOpen(undefined)} category={isModalShareOpen} />
      ) : null}
    </Utils.Container>
  );
}

interface ICategoryItemProps {
  category: SmallCategory;
  setIsModalShareOpen: React.Dispatch<React.SetStateAction<SmallCategory | undefined>>;
  queryClient: QueryClient;
  setIsModalDeleteCategoryOpen: React.Dispatch<React.SetStateAction<SmallCategory | undefined>>;
}

function CategoryItem({ category, setIsModalShareOpen, queryClient, setIsModalDeleteCategoryOpen }: ICategoryItemProps) {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [createdAtFrom, setCreatedAtFrom] = useState<string>(new Date(0).toISOString().slice(0, 10));
  const [createdAtTo, setCreatedAtTo] = useState<string>(tomorrow.toISOString().slice(0, 10));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalDownloadCSVOpen, setIsModalDownloadCSVOpen] = useState<SmallCategory | null>(null);

  const history = useHistory();

  const { mutate: updateCategory, isLoading: isUpdating } = useMutation(api.categories.updateCategory, {
    onMutate: (variables) => {
      queryClient.setQueriesData<InfiniteData<PageItem<Category>>>(["categories"], (old) => {
        if (!old || !old.pages) return [];

        return {
          pageParams: old.pageParams,
          pages: old.pages.map((o) => ({
            items: o.items.map((c) => (c.id === variables.id ? { ...c, isActive: !!variables.body.isActive } : c)),
            pagination: o.pagination,
          })),
        } as any;
      });
    },
    onSuccess: (data: Category, variables) => {
      queryClient.setQueriesData<InfiniteData<PageItem<Category>>>(["categories"], (old) => {
        if (!old || !old.pages) return [];

        return {
          pageParams: old.pageParams,
          pages: old.pages.map((o) => ({
            items: o.items.map((c) => (c.id === data.id ? { ...c, isActive: !!data.isActive } : c)),
            pagination: o.pagination,
          })),
        } as any;
      });
    },
    onError: (error, variables) => {
      queryClient.setQueriesData<InfiniteData<PageItem<Category>>>(["categories"], (old) => {
        if (!old) return [];

        return {
          pageParams: old.pageParams,
          pages: old.pages.map((o) => ({
            items: o.items.map((c) => (c.id === variables.id ? { ...c, isActive: !!!variables.body.isActive } : c)),
            pagination: o.pagination,
          })),
        } as any;
      });
    },
  });

  function handleOnCheck() {
    if (isUpdating) return;

    updateCategory({ id: category.id, body: { isActive: !category.isActive } });
  }

  function onClickOnRow(elementClicked: Element, category: SmallCategory) {
    if (elementClicked.tagName === "TD" && !elementClicked.className.includes("preventSharing")) {
      setIsModalShareOpen(category);
    }
  }

  function parseCreator(creator: null | SmallUser) {
    if (creator === null) return "";

    return `${creator.firstname} ${creator.lastname}`;
  }

  function downloadCsv(category: SmallCategory) {
    if (category === undefined) return;

    setIsLoading(true);

    api.categories
      .downloadCsv({ id: category.id, dates: { from: createdAtFrom, to: createdAtTo } })
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = category.name + ".csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
        setIsModalDownloadCSVOpen(null);
      });
  }

  function cloneCategory(category: SmallCategory) {
    api.categories
      .clone({
        id: category.id,
        cloneToken: false,
        copyOf: true,
      })
      .then((blob) => {
        queryClient.invalidateQueries(["categories"]);
      });
  }

  // useEffect(() => {
  //   setChecked(!!category.isActive);
  // }, [category]);

  return (
    <>
      <tr key={category.id} onClick={(e) => onClickOnRow(e.target as Element, category)}>
        <td className="preventSharing" colSpan={1} onClick={() => handleOnCheck()}>
          <Switch checked={!!category.isActive} />
        </td>
        <td colSpan={5}>{category.name}</td>
        <td colSpan={2}>{parseCreator(category.creator)}</td>
        <td colSpan={2}>{dateToString(category.createdAt || "")}</td>

        <td colSpan={3} onClick={(e) => e.stopPropagation()}>
          <div className={style.actionIconContainer} onClick={() => setIsModalDownloadCSVOpen(category)}>
            <FontAwesomeIcon
              title="Télécharger le CSV"
              icon={faFileCsv}
              className={classNames(style.actionIcon, "onboarding-detailledcategories-2nd")}
            />
          </div>
          <div className={style.actionIconContainer} onClick={() => setIsModalShareOpen(category)}>
            <FontAwesomeIcon
              id="onboarding-shareqrcode-next-2nd"
              title="Partager la rubrique"
              icon={faShareAlt}
              className={classNames(style.actionIcon, "onboarding-shareqrcode-2nd", "onboarding-detailledcategories-3rd")}
            />
          </div>
          <div className={style.actionIconContainer} onClick={() => cloneCategory(category)}>
            <FontAwesomeIcon
              title="Cloner la rubrique"
              icon={faClone}
              className={classNames(style.actionIcon, "onboarding-detailledcategories-4th")}
            />
          </div>
          <div className={style.actionIconContainer} onClick={() => history.push(`/categories/${category.id}/edit`)}>
            <FontAwesomeIcon
              title="Modifier la rubrique"
              icon={faPen}
              className={classNames(style.actionIcon, "onboarding-detailledcategories-5th")}
            />
          </div>
          <div className={style.actionIconContainer} onClick={() => setIsModalDeleteCategoryOpen(category)}>
            <FontAwesomeIcon
              title="Supprimer la rubrique"
              icon={faTrash}
              className={classNames(style.actionIcon, "onboarding-detailledcategories-6th")}
            />
          </div>
        </td>
      </tr>

      <Modal title="Télécharger le CSV" onClose={() => setIsModalDownloadCSVOpen(null)} isOpen={isModalDownloadCSVOpen !== null}>
        <div className={style.modalContent}>
          <p>
            <span className={style.bold}>Attention</span>, le fichier CSV contient toutes les questions de la rubrique ainsi que les réponses
            associées. Il est destiné à être utilisé pour importer les données dans un autre outil.
          </p>

          <p>
            Nom de la rubrique : <span className={style.bold}>{isModalDownloadCSVOpen?.name}</span>
          </p>

          <Utils.Input
            label="Date du (inclus)"
            placeholder="Date du"
            type="date"
            defaultValue={createdAtFrom}
            value={createdAtFrom}
            style={createdAtFrom && createdAtFrom.length <= 0 ? { color: "var(--secondary-text)" } : {}}
            onChange={(e) => setCreatedAtFrom(e.target.value)}
          />

          <Utils.Input
            label="Au (exclu)"
            placeholder="Au"
            type="date"
            defaultValue={createdAtTo}
            value={createdAtTo}
            style={createdAtTo && createdAtTo.length <= 0 ? { color: "var(--secondary-text)" } : {}}
            onChange={(e) => setCreatedAtTo(e.target.value)}
          />

          <Utils.Button
            className={style.downloadButton}
            fullWidth={false}
            disabled={!isModalDownloadCSVOpen || isLoading}
            onClick={() => isModalDownloadCSVOpen && downloadCsv(isModalDownloadCSVOpen)}
          >
            Télécharger le CSV
          </Utils.Button>
        </div>
      </Modal>
    </>
  );
}
