import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";

import "./index.css";
import { UserContextProvider } from "./utils/context/User";
import { HasCheckLoginContextProvider } from "./utils/context/HasCheckLogin";
import { BrowserRouter as Router, useHistory } from "react-router-dom";

// if (process.env.NODE_ENV === 'production') {
//   console.log = function () {};
// }

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <UserContextProvider>
      <HasCheckLoginContextProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <App />
          </Router>
        </QueryClientProvider>
      </HasCheckLoginContextProvider>
    </UserContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
